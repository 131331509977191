<template>
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M14.4139 7.52056C13.2497 8.66145 11.6756 9.02021 10.5553 8.51076L9.47902 9.56554L14.5165 14.5022L13.4841 15.5139L8.44664 10.5773L3.40192 15.5067L2.36954 14.495L9.51563 7.49186C8.99578 6.39402 9.36187 4.85132 10.526 3.71044C11.9318 2.33993 13.938 2.08162 15.007 3.12923C16.0833 4.18401 15.8124 6.15006 14.4139 7.52056ZM7.66319 7.77885L5.59113 9.80949L2.52328 6.81018C1.38108 5.68364 1.38108 3.86828 2.52328 2.74892L7.66319 7.77885Z"
        stroke="currentColor"
        stroke-width="0.8"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect x="0.666626" y="0.5" width="16" height="17" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconFourthMeal',
}
</script>
